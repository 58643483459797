import _ from 'lodash'
import { useState } from 'react'
import Step2 from '../assets/step-2.png'
import {
  getFloors,
  getFreeFlatsMesssage,
  getProperties
} from '../helpers/functions'
import { ChooseFloorProps } from '../interfaces'

const ChooseFloor = ({ reducer }: ChooseFloorProps) => {
  const { state, dispatch } = reducer
  const [hoverFloor, setHoverFlow] = useState(0)
  const properties = getProperties(state.data)
  const floors = state?.property ? getFloors(state.data, state.property) : null

  return (
    <div className='choose'>
      <div className='choose-inner'>
        <div className='choose-properties'>
          {_.map(properties, (property, index) => (
            <button
              className={`button button--big ${
                property === state.property ? ' is-active' : ''
              }`}
              onClick={() => {
                dispatch({ type: 'setProperty', payload: property })
                dispatch({ type: 'setStep', payload: 'floor' })
              }}
              key={index}
            >
              {property}
            </button>
          ))}
        </div>
        <div className='choose-content'>
          <div className='choose-floors'>
            {floors
              ? _.map(floors, (floor, index) => (
                  <button
                    className={`button${
                      hoverFloor === floor ? ' is-hovered' : ''
                    }`}
                    onClick={() => {
                      dispatch({ type: 'setFloor', payload: floor })
                      dispatch({ type: 'setStep', payload: 'flat' })
                    }}
                    key={index}
                    onMouseEnter={() => setHoverFlow(floor)}
                    onMouseLeave={() => setHoverFlow(0)}
                  >
                    {floor}. NP
                    <span className='button__tag'>
                      {getFreeFlatsMesssage(state.data, state.property, floor)}
                    </span>
                  </button>
                ))
              : null}
          </div>
          <div className='choose-property'>
            <img className='choose-property__image' src={Step2} alt='' />
            <div className='choose-property__floors'>
              {floors
                ? _.map(floors, (floor, index) => (
                    <button
                      className={`choose-property__floor${
                        hoverFloor === floor ? ' is-hovered' : ''
                      }`}
                      onClick={() => {
                        dispatch({ type: 'setFloor', payload: floor })
                        dispatch({ type: 'setStep', payload: 'flat' })
                      }}
                      key={index}
                      onMouseEnter={() => setHoverFlow(floor)}
                      onMouseLeave={() => setHoverFlow(0)}
                    ></button>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseFloor
