import '../assets/property.css'
import Step1 from '../assets/step-1.jpg'
import { getNumberOfFreeFlatsByProperty } from '../helpers/functions'
import { HoverProperty1 } from '../helpers/icons'
import { ChoosePropertyProps } from '../interfaces'

const ChooseProperty = ({ reducer }: ChoosePropertyProps) => {
  const { state, dispatch } = reducer

  return (
    <div className='property'>
      <h1>Výběr nemovitosti</h1>
      <div className='property__wrap'>
        <img className='property__image' src={Step1} alt='' />
        <button
          className='property__button property__button--1'
          onClick={() => {
            dispatch({ type: 'setProperty', payload: 'Ústí towers 1' })
            dispatch({ type: 'setStep', payload: 'floor' })
          }}
        >
          <HoverProperty1 />
          <div className='property-card'>
            <div className='property-card__name'>Ústí towers 1</div>
            <div className='property-card__info'>
              {getNumberOfFreeFlatsByProperty(state.data, 'Ústí towers 1')}{' '}
              volných bytů
            </div>
          </div>
        </button>
        <button
          className='property__button property__button--2'
          onClick={() => {
            dispatch({ type: 'setProperty', payload: 'Ústí towers 2' })
            dispatch({ type: 'setStep', payload: 'floor' })
          }}
        >
          <HoverProperty1 />
          <div className='property-card'>
            <div className='property-card__name'>Ústí towers 2</div>
            <div className='property-card__info'>
              {getNumberOfFreeFlatsByProperty(state.data, 'Ústí towers 2')}{' '}
              volných bytů
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

export default ChooseProperty
