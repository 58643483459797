import _ from 'lodash'
import Step3 from '../assets/step-3.png'
import {
  getFlats,
  getFloors,
  getFreeFlatsMesssage,
  getProperties
} from '../helpers/functions'
import { ChooseFlatProps } from '../interfaces'

const ChooseFlat = ({ reducer }: ChooseFlatProps) => {
  const { state, dispatch } = reducer
  const properties = getProperties(state.data)
  const floors = state?.property ? getFloors(state.data, state.property) : null
  const flats =
    state?.property && state?.floor
      ? getFlats(state.data, state.property, state.floor)
      : null

  return (
    <div className='choose'>
      <div className='choose-inner'>
        <div className='choose-properties'>
          {_.map(properties, (property, index) => (
            <button
              className={`button button--big ${
                property === state.property ? ' is-active' : ''
              }`}
              onClick={() => {
                dispatch({ type: 'setProperty', payload: property })
                dispatch({ type: 'setStep', payload: 'floor' })
              }}
              key={index}
            >
              {property}
            </button>
          ))}
        </div>
        <div className='choose-content'>
          <div className='choose-floors'>
            {floors
              ? _.map(floors, (floor, index) => (
                  <button
                    className={`button${
                      state.floor === floor ? ' is-active' : ''
                    }`}
                    onClick={() => {
                      dispatch({ type: 'setFloor', payload: floor })
                      dispatch({ type: 'setStep', payload: 'flat' })
                    }}
                    key={index}
                  >
                    {floor}. NP
                    <span className='button__tag'>
                      {getFreeFlatsMesssage(state.data, state.property, floor)}
                    </span>
                  </button>
                ))
              : null}
          </div>
          <div className='choose-property'>
            <img className='choose-property__image' src={Step3} alt='' />
            <div className='choose-property__flats'>
              {flats
                ? _.map(flats, (flat, index) => (
                    <button
                      className={`choose-property__flat choose-property__flat--${_.replace(
                        flat.type,
                        '+',
                        ''
                      )}${flat.state === 'reserved' ? ' is-disabled' : ''}`}
                      onClick={() => {
                        dispatch({ type: 'setFlat', payload: index })
                        dispatch({ type: 'setStep', payload: 'detail' })
                      }}
                      key={index}
                    >
                      <div className='choose-flat'>
                        <div className='choose-flat__title'>{flat.id}</div>
                        <div className='choose-flat__info'>
                          {flat.type} | {flat.size} m<sup>2</sup>
                        </div>
                        <div className='choose-flat__tag-wrap'>
                          {flat.state === 'reserved' ? (
                            <div className='choose-flat__tag choose-flat__tag--reserved'>
                              Rezervovaný
                            </div>
                          ) : (
                            <div className='choose-flat__tag'>Volný</div>
                          )}
                        </div>
                        <div className='choose-flat__price'>
                          {flat.price} Kč / měsíc
                        </div>
                        <div className='choose-flat__other'>
                          Cena obsahuje energie a&nbsp;internet
                        </div>
                      </div>
                    </button>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseFlat
