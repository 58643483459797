import _ from 'lodash'
import { DataProps, DispositionType, PropertyType } from '../interfaces'

export const getProperties = (data: DataProps[]) => {
  const properties: PropertyType[] = []

  _.forEach(data, (item) => {
    if (_.indexOf(properties, item.property) === -1) {
      properties.push(item.property)
    }
  })

  return properties
}

export const getDispositions = (data: DataProps[]) => {
  const dispositions: DispositionType[] = []

  _.forEach(data, (item) => {
    if (_.indexOf(dispositions, item.type) === -1) {
      dispositions.push(item.type)
    }
  })

  return dispositions
}

export const getFloors = (data: DataProps[], property: PropertyType) => {
  const floors: number[] = []

  _.forEach(data, (item) => {
    if (_.indexOf(floors, item.floor) === -1 && property === item.property) {
      floors.push(item.floor)
    }
  })

  return floors
}

export const getFlats = (
  data: DataProps[],
  property: PropertyType,
  floor: number
) => {
  return _.filter(
    data,
    (item) => property === item.property && item.floor === floor
  )
}

export const getNumberOfFreeFlatsByProperty = (
  data: DataProps[],
  property: PropertyType
) =>
  _.filter(data, (item) => item.property === property && item.state === 'free')
    .length

export const getNumberOfFreeFlatsByFloor = (
  data: DataProps[],
  property: PropertyType,
  floor: number
) =>
  _.filter(
    data,
    (item) =>
      item.property === property &&
      item.state === 'free' &&
      item.floor === floor
  ).length

export const filterProperties = (
  data: DataProps[],
  propertyFilter: PropertyType | null,
  floorFilter: number | null,
  dispositionFilter: DispositionType | null
) => {
  const step1 = _.isNull(propertyFilter)
    ? data
    : _.filter(data, (item) => item.property === propertyFilter)
  const step2 = _.isNull(dispositionFilter)
    ? step1
    : _.filter(step1, (item) => item.type === dispositionFilter)
  const step3 = _.isNull(floorFilter)
    ? step2
    : _.filter(step2, (item) => item.floor === floorFilter)

  return step3
}

export const sortProperties = (
  data: DataProps[],
  sortType: {
    name: string
    type: 'asc' | 'desc'
  }
) => {
  return _.orderBy(data, [sortType.name], [sortType.type])
}

export const getFreeFlatsMesssage = (
  data: DataProps[],
  property: PropertyType | null,
  floor: number
) => {
  const flatCount = property
    ? getNumberOfFreeFlatsByFloor(data, property, floor)
    : 0

  if (flatCount >= 5) {
    return `${flatCount} volných bytů`
  }

  if (flatCount === 1) {
    return `${flatCount} volný byt`
  }

  if (flatCount === 0) {
    return `žádný volný byt`
  }

  return `${flatCount} volné byty`
}
