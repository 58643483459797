import { useEffect, useState } from 'react'
import { URL_DATA } from '../config'
import { DataProps } from '../interfaces'

export const useData = () => {
  const [data, setData] = useState<DataProps[]>([])

  useEffect(() => {
    const request = async () => {
      const data = await fetch(URL_DATA)

      if (data.ok) {
        const json = await data.json()

        setData(json.data)
      }
    }

    request()
  }, [])

  return data
}
