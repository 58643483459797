export const ArrowTop = () => (
  <svg width='36' height='21' viewBox='0 0 36 21'>
    <path d='M35.0605 17.9393L32.9392 20.0607L17.9999 5.12132L3.06055 20.0607L0.939224 17.9393L17.9999 0.87868L35.0605 17.9393Z' />
  </svg>
)

export const Arrows = () => (
  <svg width='6' height='11' viewBox='0 0 6 11'>
    <path d='M3 0L0 5H6L3 0Z' />
    <path d='M3 11L6 6L4.37114e-07 6L3 11Z' />
  </svg>
)

export const HoverProperty1 = () => (
  <svg viewBox='0 0 385 397'>
    <path d='M0 24.5567V358.63L26.1978 381.652H29.2799L47.2588 397L367.283 357.095V346.863L357.523 343.794L384.748 340.724V43.4858L25.1704 0L0 24.5567Z' />
  </svg>
)
