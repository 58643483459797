import { useEffect, useReducer } from 'react'
import { DataProps, PropertyType } from '../interfaces'

const initialState = (data: DataProps[]) => ({
  data,
  property: null as PropertyType | null,
  floor: null as NUMBER_TYPE,
  flat: null as NUMBER_TYPE,
  step: 'property' as STEP_TYPE
})

export interface STATE_TYPE {
  data: DataProps[]
  property: PropertyType | null
  floor: NUMBER_TYPE
  flat: NUMBER_TYPE
  step: STEP_TYPE
}
export type NUMBER_TYPE = number | null
export type STEP_TYPE = 'property' | 'floor' | 'flat' | 'detail'
export type ACTION_TYPE =
  | { type: 'setProperty'; payload: PropertyType | null }
  | { type: 'setFloor'; payload: NUMBER_TYPE }
  | { type: 'setFlat'; payload: NUMBER_TYPE }
  | { type: 'setStep'; payload: STEP_TYPE }
  | { type: 'setData'; payload: DataProps[] }

const reducer = (state: STATE_TYPE, action: ACTION_TYPE) => {
  switch (action.type) {
    case 'setProperty': {
      return { ...state, property: action.payload }
    }
    case 'setFloor': {
      return { ...state, floor: action.payload }
    }
    case 'setFlat': {
      return { ...state, flat: action.payload }
    }
    case 'setStep': {
      return { ...state, step: action.payload }
    }
    case 'setData': {
      return { ...state, data: action.payload }
    }
  }
}

const useDataReducer = (data: DataProps[]) => {
  const initialStateTemp = initialState(data)
  const [state, dispatch] = useReducer(reducer, initialStateTemp)

  useEffect(() => {
    if (data.length) {
      dispatch({ type: 'setData', payload: data })
    }
  }, [data, initialStateTemp.data])

  return {
    state,
    dispatch
  }
}

export default useDataReducer
