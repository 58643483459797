import { DetailProps } from '../interfaces'

const Detail = ({ reducer }: DetailProps) => {
  const { state, dispatch } = reducer

  return (
    <div className='Detail'>
      <h1>Detail</h1>
      <div className=''>detail</div>
    </div>
  )
}

export default Detail
