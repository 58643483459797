import Table from './components/Table'
import Wizard from './components/Wizard'
import useDataReducer from './helpers/reducer'
import { useData } from './helpers/useData'

const App = () => {
  const data = useData()
  const reducer1 = useDataReducer(data)
  const reducer2 = useDataReducer(data)

  if (!data.length) {
    return <div>Loading</div>
  }

  return (
    <div className='app'>
      <Wizard reducer={reducer1} />
      <Table reducer={reducer2} />
    </div>
  )
}

export default App
