import { WizardProps } from '../interfaces'
import ChooseFlat from './ChooseFlat'
import ChooseFloor from './ChooseFloor'
import ChooseProperty from './ChooseProperty'
import Detail from './Detail'

const Wizard = ({ reducer }: WizardProps) => {
  const { state } = reducer

  return (
    <div className='wizard'>
      {state.step === 'property' ? <ChooseProperty reducer={reducer} /> : null}
      {state.step === 'floor' ? <ChooseFloor reducer={reducer} /> : null}
      {state.step === 'flat' ? <ChooseFlat reducer={reducer} /> : null}
      {state.step === 'detail' ? <Detail reducer={reducer} /> : null}
    </div>
  )
}

export default Wizard
