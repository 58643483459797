import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import _ from 'lodash'
import { useState } from 'react'
import '../assets/table.css'
import { INITIAL_TABLE_STATE } from '../config'
import {
  filterProperties,
  getDispositions,
  getFloors,
  getProperties,
  sortProperties
} from '../helpers/functions'
import { Arrows, ArrowTop } from '../helpers/icons'
import {
  DispositionType,
  PropertyType,
  SortProps,
  SortType,
  TableProps
} from '../interfaces'

dayjs.extend(customParseFormat)

const Table = ({ reducer }: TableProps) => {
  const { state } = reducer
  const [activeTable, setActiveTable] = useState(INITIAL_TABLE_STATE)
  const [propertyFilter, setPropertyFilter] = useState<PropertyType | null>(
    null
  )
  const [dispositionFilter, setDispositionFilter] =
    useState<DispositionType | null>(null)
  const [floorFilter, setFloorFilter] = useState<number | null>(null)
  const [typeSort, setTypeSort] = useState<SortProps>({
    name: 'id',
    type: 'asc'
  })

  const properties = getProperties(state.data)
  const dispositions = getDispositions(state.data)
  const floors = getFloors(state.data, properties[0])

  const filteredProperties = filterProperties(
    state.data,
    propertyFilter,
    floorFilter,
    dispositionFilter
  )
  const sortedProperties = sortProperties(filteredProperties, typeSort)

  const handleSort = (name: SortType) => {
    const type = typeSort.type === 'asc' ? 'desc' : 'asc'

    setTypeSort({
      name,
      type
    })
  }

  const getFormatedData = (date: string) => {
    const now = dayjs()
    const dateTemp = dayjs(date, 'MM-DD-YYYY', true) // strict parsing

    if (now >= dateTemp) {
      return 'Ihned'
    }

    return `od ${dateTemp.format('D. M. YYYY')}`
  }

  return (
    <div className='inner'>
      <div className={`table${activeTable ? ' is-active' : ''}`}>
        <div
          className='table-header'
          onClick={() => {
            setActiveTable(!activeTable)
          }}
        >
          <h2 className='table__heading'>Přehled jednotek a ceník</h2>
          <div className='table__opener'>
            <ArrowTop />
          </div>
        </div>
        <div className='table-buttons hidden'>
          <div className='table-buttons__row'>
            <button
              className={`button ${
                _.isNull(propertyFilter) ? ' is-active' : ''
              }`}
              onClick={() => {
                setPropertyFilter(null)
              }}
            >
              Všechny budovy
            </button>
            {_.map(properties, (property, index) => (
              <button
                className={`button ${
                  property === propertyFilter ? ' is-active' : ''
                }`}
                key={index}
                onClick={() => {
                  setPropertyFilter(property)
                }}
              >
                {property}
              </button>
            ))}
          </div>
          <div className='table-buttons__row'>
            <button
              className={`button ${_.isNull(floorFilter) ? ' is-active' : ''}`}
              onClick={() => {
                setFloorFilter(null)
              }}
            >
              Všechny patra
            </button>
            {_.map(floors, (floor, index) => (
              <button
                className={`button ${
                  floor === floorFilter ? ' is-active' : ''
                }`}
                key={index}
                onClick={() => {
                  setFloorFilter(floor)
                }}
              >
                {floor}.NP
              </button>
            ))}
          </div>
          <div className='table-buttons__row'>
            <button
              className={`button ${
                _.isNull(dispositionFilter) ? ' is-active' : ''
              }`}
              onClick={() => {
                setDispositionFilter(null)
              }}
            >
              Všechny dispozice
            </button>
            {_.map(dispositions, (disposition, index) => (
              <button
                className={`button ${
                  disposition === dispositionFilter ? ' is-active' : ''
                }`}
                key={index}
                onClick={() => {
                  setDispositionFilter(disposition)
                }}
              >
                {disposition}
              </button>
            ))}
          </div>
        </div>
        <div className='table-data hidden'>
          <div className='table-data__header'>
            <div className='table-data__row'>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('id')
                }}
              >
                Číslo
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('property')
                }}
              >
                Budova
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('floor')
                }}
              >
                Patro
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('type')
                }}
              >
                Typ
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('size')
                }}
              >
                Velikost
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('garden')
                }}
              >
                Předzahrádka
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('move_in')
                }}
              >
                K nastěhování
                <Arrows />
              </div>
              <div
                className='table-data__col'
                onClick={() => {
                  handleSort('price')
                }}
              >
                Cena za pronájem
                <Arrows />
              </div>
              <div className='table-data__col'></div>
            </div>
          </div>
          <div className='table-data__items'>
            {_.map(sortedProperties, (property) => (
              <div
                className={`table-data__row ${
                  property.state === 'reserved' ? ' is-disabled' : ''
                }`}
                key={property.id}
              >
                <div className='table-data__col'>{property.id}</div>
                <div className='table-data__col'>{property.property}</div>
                <div className='table-data__col'>{property.floor}. NP</div>
                <div className='table-data__col'>{property.type}</div>
                <div className='table-data__col'>{property.size}</div>
                <div className='table-data__col'>
                  {property.garden ? 'ANO' : 'NE'}
                </div>
                <div className='table-data__col'>
                  {getFormatedData(property.move_in)}
                </div>
                <div className='table-data__col'>
                  {property.price} Kč / měsíc
                </div>
                <div className='table-data__col'>
                  <button className='button'>Přejít na detail</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Table
